// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationDetailsPanel__sellIt___YaT_F{margin-top:0;margin-bottom:24px}.ReservationDetailsPanel__card___XI7kR{background-color:var(--panelBlue);border-radius:10px;margin-bottom:8px}.ReservationDetailsPanel__cancelButton___xtz5e{border:1px solid var(--pink);color:var(--pink);margin-top:2rem;min-height:2.5rem;width:100%}.ReservationDetailsPanel__divider___VwXb0{opacity:1;background-color:var(--hairline)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/ReservationDetailsPanel.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,kBAAA,CAGF,uCACE,iCAAA,CACA,kBAAA,CACA,iBAAA,CAGF,+CACE,4BAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CAGF,0CACE,SAAA,CACA,gCAAA","sourcesContent":[".sellIt {\n  margin-top: 0;\n  margin-bottom: 24px;\n}\n\n.card {\n  background-color: var(--panelBlue);\n  border-radius: 10px;\n  margin-bottom: 8px;\n}\n\n.cancelButton {\n  border: 1px solid var(--pink);\n  color: var(--pink);\n  margin-top: 2rem;\n  min-height: 2.5rem;\n  width: 100%;\n}\n\n.divider {\n  opacity: 1;\n  background-color: var(--hairline);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sellIt": "ReservationDetailsPanel__sellIt___YaT_F",
	"card": "ReservationDetailsPanel__card___XI7kR",
	"cancelButton": "ReservationDetailsPanel__cancelButton___xtz5e",
	"divider": "ReservationDetailsPanel__divider___VwXb0"
};
export default ___CSS_LOADER_EXPORT___;
