// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantDetails__container___PVHv0{display:flex;flex-direction:column;gap:2rem}.RestaurantDetails__container___PVHv0 h2{margin:0}.RestaurantDetails__container___PVHv0 a{align-items:center;display:flex;justify-content:space-between}.RestaurantDetails__container___PVHv0 a span{color:var(--white70);font-size:inherit;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:80%}.RestaurantDetails__container___PVHv0 a svg{stroke:var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/RestaurantDetails.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,yCACE,QAAA,CAGF,wCACE,kBAAA,CACA,YAAA,CACA,6BAAA,CAEA,6CACE,oBAAA,CACA,iBAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CAGF,4CACE,uBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n\n  h2 {\n    margin: 0;\n  }\n\n  a {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n\n    span {\n      color: var(--white70);\n      font-size: inherit;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n      width: 80%;\n    }\n\n    svg {\n      stroke: var(--accent300);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RestaurantDetails__container___PVHv0"
};
export default ___CSS_LOADER_EXPORT___;
