// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferTabs__container___EeEaB{width:100%;margin-top:1rem}@media only screen and (min-width: 768px){.OfferTabs__container___EeEaB{margin-top:2rem}}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/OfferTabs.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,8BACE,UAAA,CACA,eAAA,CC4BA,0CD9BF,8BAKI,eAAA,CAAA","sourcesContent":["@use '~styles/breakpoints' as *;\n\n.container {\n  width: 100%;\n  margin-top: 1rem;\n\n  @include respond-to(iPad768) {\n    margin-top: 2rem;\n  }\n}\n","@use 'sass:map';\n\n/* stylelint-disable value-keyword-case */\n$-breakpoints: (\n  // iPhone SE 2nd & 3rd gen, iPhone X, iPhone XS, iPhone 6, iPhone 6s, iPhone 7, iPhone 8, iPhone 11 Pro, iPhone 12 mini, iPhone 13 mini\n  mobile375: 375px,\n  // iPhone 12, iPhone 12 Pro, iPhone 13, iPhone 13 Pro, iPhone 14\n  mobile390: 390px,\n  // iPhone XR, iPhone XS Max, iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8 Plus, iPhone 11, iPhone 11 Pro Max\n  mobile414: 414px,\n  // iPad 1, iPad 2, iPad 3, iPad 4, iPad 5, iPad 6, iPad Air 1, iPad Air 2, iPad mini 1, iPad mini 2, iPad mini 3, iPad mini 4, iPad Mini 5, iPad Pro 1 9.7”\n  iPad768: 768px,\n  // iPad 7, iPad 8, iPad 9\n  iPad810: 810px,\n  // iPad 10, iPad Air 4, iPad Air 5\n  iPad820: 820px,\n  // iPad Air 3, iPad Pro 2 10.5\", iPad Pro 3 11\", iPad Pro 4 11\", iPad Pro 5 11\", iPad Pro 6 11\"\n  iPad834: 834px,\n  // iPad Pro 1 12.9\", iPad Pro 2 12.9\", iPad Pro 3 12.9\", iPad Pro 4 12.9\", iPad Pro 5 12.9\", iPad Pro 6 12.9\"\n  iPad1024: 1024px,\n  // HD, WXGA, SXGA– screens\n  desktop1280: 1280px\n);\n/* stylelint-enable */\n\n@mixin respond-to($media) {\n  $min-width: map.get($-breakpoints, $media);\n\n  @if not $min-width {\n    @error \"Invalid breakpoint: #{$media}. Valid breakpoints are: #{map.keys($-breakpoints)}\";\n  }\n\n  @media only screen and (min-width: $min-width) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OfferTabs__container___EeEaB"
};
export default ___CSS_LOADER_EXPORT___;
