// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoTabContentNarrow__slide___W6Tqa{flex:0 0 50%;min-width:0;background-color:var(--darkGrey300);padding:1rem 1rem 0;margin:1rem;display:flex;flex-direction:column;border-radius:8px}.InfoTabContentNarrow__slide___W6Tqa h4{margin:0 0 .5rem}.InfoTabContentNarrow__slide___W6Tqa p{margin:0 0 1rem}.InfoTabContentNarrow__slide___W6Tqa img{width:100%;height:auto;background-color:var(--primary300);padding:.5rem;margin-top:auto;border-radius:4px}", "",{"version":3,"sources":["webpack://./src/webReservations/landing/InfoTabContentNarrow.scss"],"names":[],"mappings":"AAEA,qCACE,YAAA,CACA,WAAA,CACA,mCAAA,CACA,mBAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CAEA,wCACE,gBAAA,CAGF,uCACE,eAAA,CAGF,yCACE,UAAA,CACA,WAAA,CACA,kCAAA,CACA,aAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":["@use '~styles/breakpoints' as *;\n\n.slide {\n  flex: 0 0 50%;\n  min-width: 0;\n  background-color: var(--darkGrey300);\n  padding: 1rem 1rem 0;\n  margin: 1rem;\n  display: flex;\n  flex-direction: column;\n  border-radius: 8px;\n\n  h4 {\n    margin: 0 0 0.5rem;\n  }\n\n  p {\n    margin: 0 0 1rem;\n  }\n\n  img {\n    width: 100%;\n    height: auto;\n    background-color: var(--primary300);\n    padding: 0.5rem;\n    margin-top: auto;\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": "InfoTabContentNarrow__slide___W6Tqa"
};
export default ___CSS_LOADER_EXPORT___;
