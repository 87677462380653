// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationAttributesGrid__container___wCITB{display:grid;gap:1rem;grid-template-columns:repeat(2, 1fr);width:100%}.ReservationAttributesGrid__attribute___WiZzH{align-items:center;column-gap:.5rem;display:flex}.ReservationAttributesGrid__attribute___WiZzH .ReservationAttributesGrid__table___lI721{text-transform:capitalize}.ReservationAttributesGrid__attribute___WiZzH .ReservationAttributesGrid__icon___rixBu{min-width:24px;stroke:var(--primary200);width:24px}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationAttributesGrid.scss"],"names":[],"mappings":"AAAA,8CACE,YAAA,CACA,QAAA,CACA,oCAAA,CACA,UAAA,CAGF,8CACE,kBAAA,CACA,gBAAA,CACA,YAAA,CAEA,wFACE,yBAAA,CAGF,uFACE,cAAA,CACA,wBAAA,CACA,UAAA","sourcesContent":[".container {\n  display: grid;\n  gap: 1rem;\n  grid-template-columns: repeat(2, 1fr);\n  width: 100%;\n}\n\n.attribute {\n  align-items: center;\n  column-gap: 0.5rem;\n  display: flex;\n\n  .table {\n    text-transform: capitalize;\n  }\n\n  .icon {\n    min-width: 24px;\n    stroke: var(--primary200);\n    width: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationAttributesGrid__container___wCITB",
	"attribute": "ReservationAttributesGrid__attribute___WiZzH",
	"table": "ReservationAttributesGrid__table___lI721",
	"icon": "ReservationAttributesGrid__icon___rixBu"
};
export default ___CSS_LOADER_EXPORT___;
