import { type CancellationPolicy } from '@shared/types/reservations';
import { useRestaurantContext } from './RestaurantContext';

interface WithCancellationPolicyProps {
  cancellationPolicy: CancellationPolicy;
}

const getComponentDisplayName = <T,>(
  WrappedComponent: React.ComponentType<T>,
) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

export function withCancellationPolicy<
  T extends WithCancellationPolicyProps = WithCancellationPolicyProps,
>(WrappedComponent: React.ComponentType<T>) {
  const ComponentWithCancellationPolicy = (
    props: Omit<T, keyof WithCancellationPolicyProps>,
  ) => {
    const { restaurantDetails } = useRestaurantContext();
    const { cancellationPolicy } = restaurantDetails;
    if (!cancellationPolicy) return null;

    return (
      <WrappedComponent
        {...(props as T)}
        cancellationPolicy={cancellationPolicy}
      />
    );
  };

  ComponentWithCancellationPolicy.displayName = `withCancellationPolicy(${getComponentDisplayName(WrappedComponent)})`;

  return ComponentWithCancellationPolicy;
}
