// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageLoader__defaultImage___WiI29{background-color:var(--darkGrey200);height:100%;width:100%;z-index:var(--z_backgroundImage)}.ImageLoader__image___op5KR{width:100%;height:100%;object-fit:cover;visibility:hidden;opacity:0}.ImageLoader__imageLoaded___YDAY8{visibility:visible;opacity:1;transition:visibility 1s,opacity 1s}", "",{"version":3,"sources":["webpack://./src/webReservations/components/ImageLoader.scss"],"names":[],"mappings":"AAAA,mCACE,mCAAA,CACA,WAAA,CACA,UAAA,CACA,gCAAA,CAGF,4BACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,SAAA,CAGF,kCACE,kBAAA,CACA,SAAA,CACA,mCACE","sourcesContent":[".defaultImage {\n  background-color: var(--darkGrey200);\n  height: 100%;\n  width: 100%;\n  z-index: var(--z_backgroundImage);\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  visibility: hidden;\n  opacity: 0;\n}\n\n.imageLoaded {\n  visibility: visible;\n  opacity: 1;\n  transition:\n    visibility 1s,\n    opacity 1s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultImage": "ImageLoader__defaultImage___WiI29",
	"image": "ImageLoader__image___op5KR",
	"imageLoaded": "ImageLoader__imageLoaded___YDAY8"
};
export default ___CSS_LOADER_EXPORT___;
