import { tz } from '@date-fns/tz';
import { addMinutes, isBefore, parseISO } from 'date-fns';
import { filter, groupBy, uniq } from 'lodash-es';
import type { AvailabilityState } from '@shared/availability/availabilityReducer';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { type TSelectedAvailability } from 'webReservations/context/AvailabilityContext';
import type { Availability } from 'webReservations/restaurant/apiHelpers';

const filterAvailabilitiesByGuestCount = (
  availabilities: Availability[],
  guestCount: number,
): Availability[] =>
  filter(
    availabilities,
    (item: Availability) => guestCount === item.guestCount,
  );

const filterAvailabilitiesByTime = (
  availabilities: Availability[],
  selectedTime: string | null,
): Availability[] => {
  if (!selectedTime) {
    return availabilities;
  }

  return filter(
    availabilities,
    (item: Availability) => selectedTime === item.time,
  );
};

const filterAvailabilitiesBySelectedFloorPlanId = (
  availabilities: Availability[],
  selectedFloorPlanId: string,
): Availability[] =>
  filter(
    availabilities,
    (item: Availability) => selectedFloorPlanId === item.listing.floorPlanId,
  );

const filterAvailabilitiesBySelectedFloorPlanListingIds = (
  availabilities: Availability[],
  selectedFloorPlanListingIds: string[],
): Availability[] => {
  if (!selectedFloorPlanListingIds.length) {
    return availabilities;
  }

  return filter(availabilities, (item: Availability) =>
    selectedFloorPlanListingIds.includes(item.listing.id),
  );
};

/**
 * Sorts availabilities by `time` then `purchasePrice`, in ascending order.
 *
 * Note: offer-only availabilities (`!isBuyable`) come before availabilities
 * with a purchasePrice.
 */
const timeAndPriceSorter = (a: Availability, b: Availability) => {
  const timeComparison = a.time.localeCompare(b.time);
  if (timeComparison !== 0) {
    return timeComparison;
  }

  if (!a.isBuyable && !b.isBuyable) {
    return 0;
  }
  if (!a.isBuyable || a.purchasePrice === null) {
    return -1;
  }
  if (!b.isBuyable || b.purchasePrice === null) {
    return 1;
  }
  return a.purchasePrice - b.purchasePrice;
};

const isTrulyBiddable =
  (selectedDate: Date, timezone: string) => (availability: Availability) => {
    const thirtyMinutesFromNow = addMinutes(new Date(), 30);
    const availabilityStartTime = parseISO(
      `${toISODateFormat(selectedDate)}T${availability.time}`,
      { in: tz(timezone) },
    );

    return {
      ...availability,
      isBiddable:
        availability.isBiddable &&
        isBefore(thirtyMinutesFromNow, availabilityStartTime),
    };
  };

const isTrulyBiddableOrBuyable =
  (selectedDate: Date, timezone: string) => (availability: Availability) => {
    const thirtyMinutesFromNow = addMinutes(new Date(), 30);
    const availabilityStartTime = parseISO(
      `${toISODateFormat(selectedDate)}T${availability.time}`,
      { in: tz(timezone) },
    );

    return (
      (availability.isBiddable &&
        isBefore(thirtyMinutesFromNow, availabilityStartTime)) ||
      availability.isBuyable
    );
  };

export const getAvailabilitiesGroupedByTime = (
  state: AvailabilityState<Availability, TSelectedAvailability>,
  timezone: string,
): Record<string, Availability[]> => {
  const filterFns = [
    (availabilities: Availability[]) =>
      filterAvailabilitiesByGuestCount(
        availabilities,
        state.selectedGuestCount,
      ),
    (availabilities: Availability[]) =>
      filterAvailabilitiesBySelectedFloorPlanId(
        availabilities,
        state.selectedFloorPlanId,
      ),
    (availabilities: Availability[]) =>
      filterAvailabilitiesBySelectedFloorPlanListingIds(
        availabilities,
        state.selectedFloorPlanListingIds,
      ),
  ];

  const filteredSortedAvailabilities = filterFns
    .reduce(
      (filteredData, filterFn) => filterFn(filteredData),
      state.availabilities,
    )
    .filter(isTrulyBiddableOrBuyable(state.selectedDate, timezone))
    .sort(timeAndPriceSorter);

  return groupBy(filteredSortedAvailabilities, 'time');
};

// TODO: Refactor this function to be more readable and cohesive/single responsibility
export const calculateFilteredData = (
  state: AvailabilityState<Availability, TSelectedAvailability>,
  timezone: string,
) => {
  const { availabilities, selectedFloorPlanListingIds } = state;

  const availabilitiesFilteredByGuestCount = filterAvailabilitiesByGuestCount(
    state.availabilities,
    state.selectedGuestCount,
  );

  const availabilitiesFilteredByGuestCountAndTime = filterAvailabilitiesByTime(
    availabilitiesFilteredByGuestCount,
    state.selectedTime,
  );

  const availabilitiesFilteredByGuestCountAndTimeAndFloorPLan =
    filterAvailabilitiesBySelectedFloorPlanId(
      availabilitiesFilteredByGuestCountAndTime,
      state.selectedFloorPlanId,
    );

  const filteredSortedAvailabilities =
    filterAvailabilitiesBySelectedFloorPlanListingIds(
      availabilitiesFilteredByGuestCountAndTimeAndFloorPLan,
      selectedFloorPlanListingIds,
    )
      .map(isTrulyBiddable(state.selectedDate, timezone))
      .sort(timeAndPriceSorter);

  const hasUnsupportedGuestCount =
    !availabilitiesFilteredByGuestCount.length && !!availabilities.length;

  const expandedAvailabilityListingIds = uniq(
    availabilitiesFilteredByGuestCountAndTimeAndFloorPLan.map(
      (availability) => availability.listing.id,
    ),
  );

  return {
    filteredAvailabilities: filteredSortedAvailabilities,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  };
};

export const getSelectedFloorPlan = (
  state: AvailabilityState<Availability, TSelectedAvailability>,
) => state.floorPlans.find((fp) => fp.id === state.selectedFloorPlanId)!;
