import cx from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { centsToWholeDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import { useOfferCheckoutContext } from '../context/OfferCheckoutContext';
import { OFFER_UPDATE_ROOT_PATH } from '../paths';
import { cancelOffer, type Offer } from './apiHelpers';
import {
  sendGa4OfferUpdateAbortEvent,
  sendGa4OfferUpdateConfirmEvent,
} from './gaHelpers';
import styles from './UpdateOfferModal.scss';

export interface UpdateOfferModalProps {
  offer: Offer;
  onClose: () => void;
  isOpen: boolean;
}

export const UpdateOfferModal = ({
  onClose,
  offer,
  isOpen,
}: UpdateOfferModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setOfferCheckout } = useOfferCheckoutContext();

  const { price: offerAmount } = offer;

  const handleCancel = () => {
    sendGa4OfferUpdateAbortEvent({
      date: offer.date,
      offerAmount: offer.price,
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      time: offer.time,
    });
    onClose();
  };

  const handleUpdateOfferAmount = (): void => {
    setIsLoading(true);
    sendGa4OfferUpdateConfirmEvent({
      date: offer.date,
      offerAmount: offer.price,
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      time: offer.time,
    });

    void (async () => {
      try {
        await cancelOffer(offer.id);
        setOfferCheckout({
          date: offer.date,
          guestCount: offer.guestCount,
          listingId: offer.listing.id,
          publicName: offer.listing.publicName,
          time: offer.time,
        });
        navigate(OFFER_UPDATE_ROOT_PATH, {
          state: { restaurantId: offer.restaurant.id },
        });
        onClose();
      } catch (e) {
        if (e instanceof ApiError) {
          setErrorMessage(e.message);
        } else {
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
        reportAppError(e);
        setIsLoading(false);
      }
    })();
  };

  return (
    <Modal title="New Offer" onClose={handleCancel} isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.info}>
          <p className={cx(typography.c2)}>
            Your initial offer payment method hold will be released and replaced
            by a new hold when you proceed with the below action “Make New
            Offer.” The initial hold will be released by your bank in 5–10
            business&nbsp;days.
          </p>
          <dl className={styles.currentOfferAmount}>
            <dt className={typography.c2}>Current Offer</dt>
            <dd className={typography.h2}>{centsToWholeDollar(offerAmount)}</dd>
          </dl>
        </div>
        <div className={styles.error}>{errorMessage}</div>
        <div className={styles.actions}>
          <Button
            className={styles.button}
            isLoading={isLoading}
            label="Cancel"
            onClick={handleCancel}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            className={styles.button}
            isLoading={isLoading}
            label="Make New Offer"
            onClick={handleUpdateOfferAmount}
            variant={ButtonVariants.Primary}
          />
        </div>
      </div>
    </Modal>
  );
};
