// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FAQPage__main___nZmeg{align-items:center;display:flex;flex-direction:column;margin:0 32px 80px}.FAQPage__main___nZmeg h1{margin-bottom:0;margin-top:88px;text-align:center}.FAQPage__main___nZmeg>p{color:var(--white70);max-width:29em;text-align:center}.FAQPage__main___nZmeg>section{max-width:54rem;width:100%}.FAQPage__main___nZmeg>section .FAQPage__divider___DbVGQ{background-color:var(--hairline);margin-top:16px;opacity:1}.FAQPage__main___nZmeg>section>h2{margin:64px 0 32px;letter-spacing:.03em}", "",{"version":3,"sources":["webpack://./src/webReservations/faq/FAQPage.scss"],"names":[],"mappings":"AAAA,uBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,0BACE,eAAA,CACA,eAAA,CACA,iBAAA,CAGF,yBACE,oBAAA,CACA,cAAA,CACA,iBAAA,CAGF,+BACE,eAAA,CACA,UAAA,CAEA,yDACE,gCAAA,CACA,eAAA,CACA,SAAA,CAGF,kCACE,kBAAA,CACA,oBAAA","sourcesContent":[".main {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin: 0 32px 80px;\n\n  h1 {\n    margin-bottom: 0;\n    margin-top: 88px;\n    text-align: center;\n  }\n\n  & > p {\n    color: var(--white70);\n    max-width: 29em;\n    text-align: center;\n  }\n\n  & > section {\n    max-width: 54rem;\n    width: 100%;\n\n    .divider {\n      background-color: var(--hairline);\n      margin-top: 16px;\n      opacity: 1;\n    }\n\n    & > h2 {\n      margin: 64px 0 32px;\n      letter-spacing: 0.03em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "FAQPage__main___nZmeg",
	"divider": "FAQPage__divider___DbVGQ"
};
export default ___CSS_LOADER_EXPORT___;
