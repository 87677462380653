import { api } from '@shared/api';
import type { FloorPlanData } from '@shared/types/floorPlans';

export const getFloorPlansForRestaurantId = async (
  restaurantId: string,
  activeListingsOnly: boolean,
  signal?: AbortSignal,
) =>
  api.get<FloorPlanData[]>(
    `/restaurants/${restaurantId}/floor-plans${activeListingsOnly ? '?activeListingsOnly=true' : '?activeListingsOnly=false'}`,
    { signal },
  );

export const getFloorPlanByNameSlug = async (
  restaurantId: string,
  nameSlug: string,
  signal?: AbortSignal,
) =>
  api.get<FloorPlanData>(
    `/restaurants/${restaurantId}/floor-plans/${nameSlug}`,
    { signal },
  );
