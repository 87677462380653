import cx from 'classnames';
import { useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormCheckbox } from '@components/formInputs/ControlledFormCheckbox';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormPhoneInput } from '@components/formInputs/ControlledFormPhoneInput';
import { isValidEmailOrWhiteSpaceRule } from '@components/formInputs/rules';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { trimData } from '@shared/utils/trimData';
import typography from '~styles/typography.scss';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/AuthModalContext';
import { PRIVACY_POLICY_PATH, TERMS_OF_SERVICE_PATH } from '../paths';
import { sendRegistrationVerificationCode } from './apiHelpers';
import { AuthModal } from './AuthModal';
import styles from './SignUpModal.scss';

const isValidName = (fieldName: string, value = '') => {
  if (value.trim()) return true;

  return `${fieldName} required`;
};

const noOp = () => undefined;

interface SignUpFormData {
  email?: string;
  firstName: string;
  isTosAccepted: boolean;
  lastName: string;
  phone: string;
}

export interface SignUpModalProps {
  onAuthSuccess: () => void;
  defaultPhone: string;
}

export const SignUpModal = ({
  onAuthSuccess = noOp,
  defaultPhone = '',
}: SignUpModalProps) => {
  const signUpForm = useForm<SignUpFormData>({
    defaultValues: {
      phone: defaultPhone,
    },
  });
  const { handleSubmit } = signUpForm;
  const { closeModal, nextModal } = useAuthModalContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [twilioError, setTwilioError] = useState<string | undefined>();

  const submitHandler: SubmitHandler<SignUpFormData> = async (
    data,
  ): Promise<void> => {
    const { email, firstName, isTosAccepted, lastName, phone } = trimData(data);

    setTwilioError(undefined);
    setIsLoading(true);

    try {
      await sendRegistrationVerificationCode(phone);
      nextModal(AuthModalTypes.SIGN_UP_OTP, {
        ...(email && { email }),
        firstName,
        isTosAccepted,
        lastName,
        onAuthSuccess,
        phone,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        setTwilioError(error.message);
      } else {
        setTwilioError('An error occurred. Please try again.');
      }
      reportAppError(error);
    }
    setIsLoading(false);
  };

  return (
    <AuthModal
      label="Create Account"
      submitHandler={handleSubmit(submitHandler)}
      title="Create Account"
    >
      <ControlledFormInput
        control={signUpForm.control}
        label="First Name"
        name="firstName"
        placeholder="Jane"
        rules={{
          required: true,
          validate: {
            isValidName: (value: string) => isValidName('First Name', value),
          },
        }}
        type="text"
      />
      <ControlledFormInput
        control={signUpForm.control}
        label="Last Name"
        name="lastName"
        placeholder="Doe"
        rules={{
          required: true,
          validate: {
            isValidName: (value: string) => isValidName('Last Name', value),
          },
        }}
        type="text"
      />
      <ControlledFormInput
        control={signUpForm.control}
        label="Email Address (optional)"
        name="email"
        placeholder="jane@example.com"
        rules={{ validate: { isValidEmailOrWhiteSpaceRule } }}
        type="email"
      />
      <ControlledFormPhoneInput
        control={signUpForm.control}
        customError={twilioError}
        label="Phone Number"
        name="phone"
        rules={{
          required: true,
        }}
      />
      <p className={cx(typography.c2, styles.info)}>
        {'By selecting the checkbox below, you agree to Peak’s '}
        <Link
          className={styles.legalLink}
          onClick={closeModal}
          to={TERMS_OF_SERVICE_PATH}
        >
          Terms of Service
        </Link>
        {' and '}
        <Link
          className={styles.legalLink}
          onClick={closeModal}
          to={PRIVACY_POLICY_PATH}
        >
          Privacy Policy
        </Link>
      </p>
      <ControlledFormCheckbox
        className={styles.acceptTos}
        control={signUpForm.control}
        defaultValue={false}
        label="I agree to Peak’s Terms"
        name="isTosAccepted"
        rules={{
          required: {
            message: 'You must agree to the terms of service',
            value: true,
          },
        }}
      />
      <Button
        className={styles.block}
        isDisabled={isLoading}
        label="Continue"
        type="submit"
        variant={ButtonVariants.Primary}
      />
    </AuthModal>
  );
};
