// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantAboutPage__body___wCg15{align-items:center;display:flex;flex-direction:column;margin-top:64px;width:100%;padding:8px}.RestaurantAboutPage__body___wCg15 .RestaurantAboutPage__description___xcEzd{max-width:512px;text-align:center;margin-bottom:56px}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/RestaurantAboutPage.scss"],"names":[],"mappings":"AAAA,mCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CAEA,6EACE,eAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".body {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-top: 64px;\n  width: 100%;\n  padding: 8px;\n\n  .description {\n    max-width: 512px;\n    text-align: center;\n    margin-bottom: 56px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "RestaurantAboutPage__body___wCg15",
	"description": "RestaurantAboutPage__description___xcEzd"
};
export default ___CSS_LOADER_EXPORT___;
