import { createContext, type ReactNode, useMemo, useReducer } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import {
  clearSelectedAvailability,
  clearSelectedTime,
  createAvailabilityReducer,
  initialAvailabilityState,
  setAvailabilities,
  setFloorPlans,
  setSelectedAvailability,
  setSelectedDate,
  setSelectedFloorPlanId,
  setSelectedFloorPlanListingIds,
  setSelectedGuestCount,
  setSelectedTime,
} from '@shared/availability/availabilityReducer';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { reportAppError } from '@shared/reportAppError';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { todayInTimezone, toISODateFormat } from '@shared/utils/dateFormatters';
import { zonedDateTime } from '@shared/utils/dateParsers';
import {
  getFloorPlanByNameSlug,
  getFloorPlansForRestaurantId,
} from '../floorPlans/apiHelpers';
import {
  type Availability,
  type AvailabilityListing,
  getAvailabilities,
  getClosureForDate,
  type RestaurantDetails,
} from '../restaurant/apiHelpers';
import {
  calculateFilteredData,
  getAvailabilitiesGroupedByTime,
  getSelectedFloorPlan,
} from './availabilityDerivedState';
import { useRestaurantContext } from './RestaurantContext';

export interface AvailabilityOption {
  isOffer: boolean;
  isOfferOnly: boolean;
  listing: AvailabilityListing;
  purchasePrice: number | null;
}

export interface TSelectedAvailability {
  isOffer: boolean;
  listingId: string;
  purchasePrice: number | null;
  publicName: string;
  time: string;
}

export interface AvailabilityContextState {
  clearSelectedAvailability: () => void;
  clearSelectedTime: () => void;
  expandedAvailabilityListingIds: string[];
  filteredAvailabilities: Availability[];
  filteredAvailabilitiesGroupedByTime: Record<string, Availability[]>;
  hasAvailabilitiesOnGivenDay: boolean;
  hasUnsupportedGuestCount: boolean;
  isClosedToday: boolean;
  isAvailabilitiesFetchLoading: boolean;
  isFloorPlansFetchLoading: boolean;
  isSelectedDateToday: boolean;
  selectedAvailability: TSelectedAvailability | null;
  floorPlans: FloorPlanData[];
  selectedFloorPlan: FloorPlanData;
  setSelectedFloorPlanId: (floorPlanId: string) => void;
  selectedDate: Date;
  selectedFloorPlanListingIds: string[];
  selectedGuestCount: number;
  selectedTime: string | null;
  setSelectedAvailability: (availability: TSelectedAvailability) => void;
  setSelectedDate: (date: Date) => void;
  setSelectedFloorPlanListingIds: (ids: string[]) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  setSelectedTime: (time: string) => void;
}

const AvailabilityContext = createContext<AvailabilityContextState | null>(
  null,
);
AvailabilityContext.displayName = 'AvailabilityContext';

export const useAvailabilityContext = () =>
  useDefinedContext(AvailabilityContext);

interface AvailabilityContextProviderProps {
  children: ReactNode;
}

const useIsRestaurantClosedToday = (
  restaurantDetails: RestaurantDetails,
  todayInRestaurantTimezone: string,
) => {
  const { data = [] } = useAbortEffect(
    () =>
      restaurantDetails.id
        ? getClosureForDate(restaurantDetails.id, todayInRestaurantTimezone)
        : Promise.resolve([]),
    [restaurantDetails.id, todayInRestaurantTimezone],
  );
  const isClosedToday = data?.length > 0;

  return isClosedToday;
};

export const AvailabilityContextProvider = ({
  children,
}: AvailabilityContextProviderProps) => {
  const { restaurantDetails } = useRestaurantContext();
  const todayInRestaurantTimezone = useMemo(
    () => todayInTimezone(restaurantDetails.timezone),
    [restaurantDetails.timezone],
  );
  const isClosedToday = useIsRestaurantClosedToday(
    restaurantDetails,
    todayInRestaurantTimezone,
  );

  const guestAvailabilityReducer = createAvailabilityReducer<
    Availability,
    TSelectedAvailability
  >();

  const { floorPlanNameSlug } = useParams();
  const [searchParams] = useSearchParams();
  const dateSearchParam = searchParams.get('date');

  const selectedDateFromSearchParams = dateSearchParam
    ? zonedDateTime(dateSearchParam, '00:00:00', restaurantDetails.timezone)
    : undefined;

  const [state, dispatch] = useReducer(
    guestAvailabilityReducer,
    initialAvailabilityState<Availability, TSelectedAvailability>(
      restaurantDetails.timezone,
      selectedDateFromSearchParams,
    ),
  );
  const {
    availabilities,
    selectedGuestCount,
    selectedTime,
    selectedDate,
    selectedFloorPlanListingIds,
    selectedAvailability,
    floorPlans,
  } = state;

  const { isPending: isFloorPlansFetchLoading } = useAbortEffect(
    {
      effect: async (signal) => {
        try {
          const allFloorPlans = floorPlanNameSlug
            ? [
                await getFloorPlanByNameSlug(
                  restaurantDetails.id,
                  floorPlanNameSlug,
                  signal,
                ),
              ]
            : await getFloorPlansForRestaurantId(
                restaurantDetails.id,
                true,
                signal,
              );
          dispatch(setFloorPlans(allFloorPlans));
          dispatch(setSelectedFloorPlanId(allFloorPlans[0].id));

          return allFloorPlans;
        } catch {
          dispatch(
            setFloorPlans([
              {
                id: 'not-loaded',
                backgroundSrc: undefined,
                floorPlanTables: [],
                name: 'not loaded',
              },
            ]),
          );
          dispatch(setSelectedFloorPlanId('not-loaded'));

          return [
            {
              id: 'not-loaded',
              backgroundSrc: undefined,
              floorPlanTables: [],
              name: 'not loaded',
            },
          ];
        }
      },
      onError: (e) => {
        if (e instanceof ApiError) {
          errorToast({
            message: e.message,
          });
        } else {
          errorToast({
            message: 'Failed to fetch floors plans. Please try again.',
          });
        }
        reportAppError(e);
      },
      throwOnError: false,
    },
    [],
  );

  const { isPending: isAvailabilitiesFetchLoading } =
    useAbortEffect(async () => {
      const allAvailabilities = await getAvailabilities(
        restaurantDetails.id,
        toISODateFormat(selectedDate),
      );
      dispatch(setAvailabilities(allAvailabilities));
    }, [selectedDate, restaurantDetails]);

  const {
    filteredAvailabilities,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  } = calculateFilteredData(state, restaurantDetails.timezone);

  const filteredAvailabilitiesGroupedByTime = getAvailabilitiesGroupedByTime(
    state,
    restaurantDetails.timezone,
  );

  const isSelectedDateToday =
    todayInTimezone(restaurantDetails.timezone) ===
    toISODateFormat(selectedDate);

  const value = useMemo<AvailabilityContextState>(
    () => ({
      clearSelectedAvailability: () => dispatch(clearSelectedAvailability()),
      clearSelectedTime: () => dispatch(clearSelectedTime()),
      filteredAvailabilities,
      filteredAvailabilitiesGroupedByTime,
      hasAvailabilitiesOnGivenDay: !!availabilities.length,
      hasUnsupportedGuestCount,
      isClosedToday,
      isAvailabilitiesFetchLoading,
      isFloorPlansFetchLoading,
      selectedAvailability,
      selectedDate,
      selectedGuestCount,
      selectedFloorPlanListingIds,
      selectedTime,
      setSelectedAvailability: (availability: TSelectedAvailability) =>
        dispatch(setSelectedAvailability(availability)),
      setSelectedDate: (date: Date) => dispatch(setSelectedDate(date)),
      setSelectedFloorPlanListingIds: (floorPlanListingIds: string[]) =>
        dispatch(setSelectedFloorPlanListingIds(floorPlanListingIds)),
      setSelectedGuestCount: (guestCount: number) =>
        dispatch(setSelectedGuestCount(guestCount)),
      setSelectedTime: (time: string) => dispatch(setSelectedTime(time)),
      expandedAvailabilityListingIds,
      isSelectedDateToday,
      floorPlans,
      selectedFloorPlan: getSelectedFloorPlan(state),
      setSelectedFloorPlanId: (floorPlanId: string) =>
        dispatch(setSelectedFloorPlanId(floorPlanId)),
    }),
    [
      state,
      isAvailabilitiesFetchLoading,
      isFloorPlansFetchLoading,
      searchParams,
    ],
  );

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  );
};
