// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignUpModal__block___KvJWk{width:100%}.SignUpModal__acceptTos___bikdo{align-self:flex-start}.SignUpModal__info___jZHhm{color:var(--white70);margin:0}.SignUpModal__info___jZHhm a{color:inherit;font-size:inherit;text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/webReservations/auth/SignUpModal.scss"],"names":[],"mappings":"AAAA,4BACE,UAAA,CAGF,gCACE,qBAAA,CAGF,2BACE,oBAAA,CACA,QAAA,CAEA,6BACE,aAAA,CACA,iBAAA,CACA,yBAAA","sourcesContent":[".block {\n  width: 100%;\n}\n\n.acceptTos {\n  align-self: flex-start;\n}\n\n.info {\n  color: var(--white70);\n  margin: 0;\n\n  a {\n    color: inherit;\n    font-size: inherit;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": "SignUpModal__block___KvJWk",
	"acceptTos": "SignUpModal__acceptTos___bikdo",
	"info": "SignUpModal__info___jZHhm"
};
export default ___CSS_LOADER_EXPORT___;
