// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationAttributesInline__container___lGv6G{display:flex;flex-wrap:nowrap;align-items:center;align-content:left;gap:16px 32px;padding-top:24px}.ReservationAttributesInline__attribute___eH2so{column-gap:8px;display:flex;align-items:center}.ReservationAttributesInline__attribute___eH2so:not(:last-child){white-space:nowrap}.ReservationAttributesInline__attribute___eH2so .ReservationAttributesInline__table___SHuja{text-transform:capitalize}.ReservationAttributesInline__attribute___eH2so .ReservationAttributesInline__icon___ApcFH{stroke:var(--primary200);width:24px;min-width:24px}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationAttributesInline.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,gBAAA,CAGF,gDACE,cAAA,CACA,YAAA,CACA,kBAAA,CAEA,iEACE,kBAAA,CAGF,4FACE,yBAAA,CAGF,2FACE,wBAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  align-content: left;\n  gap: 16px 32px;\n  padding-top: 24px;\n}\n\n.attribute {\n  column-gap: 8px;\n  display: flex;\n  align-items: center;\n\n  &:not(:last-child) {\n    white-space: nowrap;\n  }\n\n  .table {\n    text-transform: capitalize;\n  }\n\n  .icon {\n    stroke: var(--primary200);\n    width: 24px;\n    min-width: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationAttributesInline__container___lGv6G",
	"attribute": "ReservationAttributesInline__attribute___eH2so",
	"table": "ReservationAttributesInline__table___SHuja",
	"icon": "ReservationAttributesInline__icon___ApcFH"
};
export default ___CSS_LOADER_EXPORT___;
