// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationNote__button___SwvvO{padding:4px 0;cursor:pointer;background-color:rgba(0,0,0,0);border:none;display:flex;flex-direction:row;justify-content:space-between;align-items:center;width:100%}.ReservationNote__button___SwvvO>svg{height:1.5rem;stroke-width:1.5px;stroke:var(--accent300);transform:scaleX(-1);width:1.5rem;margin-left:auto}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/ReservationNote.scss"],"names":[],"mappings":"AAAA,iCACE,aAAA,CACA,cAAA,CACA,8BAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CAEA,qCACE,aAAA,CACA,kBAAA,CACA,uBAAA,CACA,oBAAA,CACA,YAAA,CACA,gBAAA","sourcesContent":[".button {\n  padding: 4px 0;\n  cursor: pointer;\n  background-color: transparent;\n  border: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n\n  > svg {\n    height: 1.5rem;\n    stroke-width: 1.5px;\n    stroke: var(--accent300);\n    transform: scaleX(-1);\n    width: 1.5rem;\n    margin-left: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ReservationNote__button___SwvvO"
};
export default ___CSS_LOADER_EXPORT___;
