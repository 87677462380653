// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubNavLink__button___DPMQ9{align-items:center;background-color:rgba(0,0,0,0);border-bottom:2px solid rgba(0,0,0,0);border-top:2px solid rgba(0,0,0,0);color:var(--white);cursor:pointer;display:flex;height:100%;justify-content:center;padding:.5rem;width:5.5rem}.SubNavLink__button___DPMQ9:active,.SubNavLink__button___DPMQ9:focus,.SubNavLink__button___DPMQ9:hover{border-bottom:2px solid var(--primary200);outline:none}.SubNavLink__active___Jhm0c{border-bottom:2px solid var(--accent300)}.SubNavLink__helpButton___OH9c_{gap:.5rem;margin-left:auto}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/SubNavLink.scss"],"names":[],"mappings":"AAEA,4BACE,kBAAA,CACA,8BAAA,CACA,qCAAA,CACA,kCAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,aAAA,CACA,YAAA,CAEA,uGAGE,yCAAA,CACA,YAAA,CAIJ,4BACE,wCAAA,CAGF,gCACE,SAAA,CACA,gBAAA","sourcesContent":["@use '~styles/breakpoints' as *;\n\n.button {\n  align-items: center;\n  background-color: transparent;\n  border-bottom: 2px solid transparent;\n  border-top: 2px solid transparent;\n  color: var(--white);\n  cursor: pointer;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  padding: 0.5rem;\n  width: 5.5rem;\n\n  &:active,\n  &:focus,\n  &:hover {\n    border-bottom: 2px solid var(--primary200);\n    outline: none;\n  }\n}\n\n.active {\n  border-bottom: 2px solid var(--accent300);\n}\n\n.helpButton {\n  gap: 0.5rem;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "SubNavLink__button___DPMQ9",
	"active": "SubNavLink__active___Jhm0c",
	"helpButton": "SubNavLink__helpButton___OH9c_"
};
export default ___CSS_LOADER_EXPORT___;
