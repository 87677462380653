// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailVerification__container___RFyKO{align-items:center;color:var(--white);display:flex;flex-direction:column;justify-content:center;margin:0 auto;max-width:28rem;padding:4rem 2rem;text-align:center;width:100%}.EmailVerification__logo___o8cOX{height:128px;margin-bottom:4rem;stroke:var(--accent300)}.EmailVerification__resendButton___saLYL{width:100%}.EmailVerification__statusMessage___pgdK5{margin-top:16px;text-align:center;visibility:hidden}.EmailVerification__showStatus___M9r1L{visibility:visible}", "",{"version":3,"sources":["webpack://./src/webReservations/email/EmailVerification.scss"],"names":[],"mappings":"AAAA,sCACE,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,aAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA,CAGF,iCACE,YAAA,CACA,kBAAA,CACA,uBAAA,CAGF,yCACE,UAAA,CAGF,0CACE,eAAA,CACA,iBAAA,CACA,iBAAA,CAGF,uCACE,kBAAA","sourcesContent":[".container {\n  align-items: center;\n  color: var(--white);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin: 0 auto;\n  max-width: 28rem;\n  padding: 4rem 2rem;\n  text-align: center;\n  width: 100%;\n}\n\n.logo {\n  height: 128px;\n  margin-bottom: 4rem;\n  stroke: var(--accent300);\n}\n\n.resendButton {\n  width: 100%;\n}\n\n.statusMessage {\n  margin-top: 16px;\n  text-align: center;\n  visibility: hidden;\n}\n\n.showStatus {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EmailVerification__container___RFyKO",
	"logo": "EmailVerification__logo___o8cOX",
	"resendButton": "EmailVerification__resendButton___saLYL",
	"statusMessage": "EmailVerification__statusMessage___pgdK5",
	"showStatus": "EmailVerification__showStatus___M9r1L"
};
export default ___CSS_LOADER_EXPORT___;
