import { api } from '../api';

interface CreateReservationRequest {
  cancellationPolicyId: string | null;
  date: string;
  guestCount: number;
  listingId: string;
  purchasePrice: number;
  time: string;
  referralCode: string | null;
}

export interface CreatedReservation {
  fee: number;
  id: string;
  price: number;
  receiptNumber: string;
  tax: number;
  total: number;
  type: 'initial purchase';
}

export type CreateReservationResponse = CreatedReservation & {
  paymentIntentClientSecret?: string;
  setupIntentClientSecret?: string;
};

export const bookReservation = async (
  reservationDetails: CreateReservationRequest,
): Promise<CreateReservationResponse> =>
  api.post(`/reservations`, reservationDetails);

interface CreateOfferRequest {
  date: string;
  expectedCancellationPolicyId: string | null;
  guestCount: number;
  listingId: string;
  offerAmount: number;
  paymentMethodId: string;
  setupFutureCardUsage: boolean;
  time: string;
}

export interface CreatedOffer {
  fee: number;
  id: string;
  price: number;
  tax: number;
  total: number;
}

export const createOffer = async (
  payload: CreateOfferRequest,
): Promise<CreatedOffer> => api.post('/offers', payload);

export const deletePaymentMethod = async (id: string): Promise<void> =>
  api.delete(`/payment-methods/${id}`);

export interface PaymentMethod {
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  id: string;
  last4: string;
}

export const getPaymentMethods = async (): Promise<PaymentMethod[]> =>
  api.get('/payment-methods');
