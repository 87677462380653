// this gets transformed into more specific imports by @babel/preset-env
import 'core-js/stable';

import Hotjar from '@hotjar/browser';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { PRODUCTION } from '@shared/environments';
import { initGoogleAnalytics } from '@utils/googleAnalytics';
import initSentry from '@utils/sentry';
import { router } from './router';

const siteId = 5322040;
const hotjarVersion = 6;

if (process.env.ENV === PRODUCTION) {
  Hotjar.init(siteId, hotjarVersion);
}

initSentry();
initGoogleAnalytics();

const appRoot = document.getElementById('app');

if (!appRoot) {
  throw new Error('Root element not found');
}

createRoot(appRoot).render(<RouterProvider router={router} />);
