// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentMethods__newPaymentMethodContainer___UVUFu{border:2px solid var(--darkGrey100);padding:20px;margin-bottom:8px;text-align:left}.PaymentMethods__newPaymentMethodContainer___UVUFu input{margin-right:11px}.PaymentMethods__name___fCfGD{background-color:var(--primary300);border:2px solid var(--darkGrey100);color:var(--white);padding:16px;margin-top:32px;display:flex;flex-direction:column;margin-bottom:8px;width:100%}.PaymentMethods__name___fCfGD::placeholder{color:var(--darkGrey200);opacity:1}.PaymentMethods__saveCard___g2OFp{display:block;margin-top:16px}.PaymentMethods__saveCard___g2OFp input{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/webReservations/checkout/PaymentMethods.scss"],"names":[],"mappings":"AAAA,mDACE,mCAAA,CACA,YAAA,CACA,iBAAA,CACA,eAAA,CAEA,yDACE,iBAAA,CAIJ,8BACE,kCAAA,CACA,mCAAA,CACA,kBAAA,CACA,YAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CAEA,2CACE,wBAAA,CACA,SAAA,CAIJ,kCACE,aAAA,CACA,eAAA,CAEA,wCACE,gBAAA","sourcesContent":[".newPaymentMethodContainer {\n  border: 2px solid var(--darkGrey100);\n  padding: 20px;\n  margin-bottom: 8px;\n  text-align: left;\n\n  input {\n    margin-right: 11px;\n  }\n}\n\n.name {\n  background-color: var(--primary300);\n  border: 2px solid var(--darkGrey100);\n  color: var(--white);\n  padding: 16px;\n  margin-top: 32px;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  width: 100%;\n\n  &::placeholder {\n    color: var(--darkGrey200);\n    opacity: 1;\n  }\n}\n\n.saveCard {\n  display: block;\n  margin-top: 16px;\n\n  input {\n    margin-right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newPaymentMethodContainer": "PaymentMethods__newPaymentMethodContainer___UVUFu",
	"name": "PaymentMethods__name___fCfGD",
	"saveCard": "PaymentMethods__saveCard___g2OFp"
};
export default ___CSS_LOADER_EXPORT___;
