import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/AuthModalContext';
import { useUserContext } from '../context/UserContext';
import { login } from './apiHelpers';
import { VerifyOTPModal } from './VerifyOTPModal';

interface SignInOTPModalProps {
  phone: string;
  onAuthSuccess: () => void;
}

export const SignInOTPModal = ({
  onAuthSuccess,
  phone,
}: SignInOTPModalProps) => {
  const { setUser, setIsAuthenticated } = useUserContext();
  const { openModal, closeModal, nextModal } = useAuthModalContext();

  const onClickCreateAccount = () => {
    openModal(AuthModalTypes.SIGN_UP, { onAuthSuccess, defaultPhone: phone });
  };

  const onSubmit = async (phoneVerificationCode: string) => {
    try {
      const response = await login({
        phone,
        phoneVerificationCode,
      });

      if (response.isRegistered) {
        closeModal();
        setIsAuthenticated(true);
        setUser(response);
        onAuthSuccess();
      } else {
        nextModal(AuthModalTypes.UNREGISTERED_USER_LOGIN, {
          onAuthSuccess,
        });
      }
      return response;
    } catch (error) {
      reportAppError(error);
      if (error instanceof ApiError) {
        return error;
      }
      return error as Error;
    }
  };

  return (
    <VerifyOTPModal
      label="Phone Number Verification"
      onClickCreateAccount={onClickCreateAccount}
      onSubmit={onSubmit}
      phone={phone}
      submitButtonLabel="Login"
    />
  );
};
