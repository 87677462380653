// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SavedPaymentMethod__main___GN9xG{display:flex;flex-direction:row;border:1px solid hsla(0,0%,100%,.3);border-radius:5px;gap:1rem;margin-bottom:8px;padding:16px}.SavedPaymentMethod__paymentMethod___ZrQAc{flex:1;align-items:center;display:flex;justify-content:space-between}.SavedPaymentMethod__paymentMethod___ZrQAc input{margin-right:11px}.SavedPaymentMethod__paymentMethod___ZrQAc input:focus{outline:var(--primary100) auto medium}.SavedPaymentMethod__brand___LoKuB{text-transform:capitalize}.SavedPaymentMethod__expiration___OwrwY{text-align:right}", "",{"version":3,"sources":["webpack://./src/webReservations/checkout/SavedPaymentMethod.scss"],"names":[],"mappings":"AAAA,kCACE,YAAA,CACA,kBAAA,CACA,mCAAA,CACA,iBAAA,CACA,QAAA,CACA,iBAAA,CACA,YAAA,CAGF,2CACE,MAAA,CACA,kBAAA,CACA,YAAA,CACA,6BAAA,CAEA,iDACE,iBAAA,CAEA,uDACE,qCAAA,CAKN,mCACE,yBAAA,CAGF,wCACE,gBAAA","sourcesContent":[".main {\n  display: flex;\n  flex-direction: row;\n  border: 1px solid rgba(255 255 255 / 30%);\n  border-radius: 5px;\n  gap: 1rem;\n  margin-bottom: 8px;\n  padding: 16px;\n}\n\n.paymentMethod {\n  flex: 1;\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n\n  input {\n    margin-right: 11px;\n\n    &:focus {\n      outline: var(--primary100) auto medium;\n    }\n  }\n}\n\n.brand {\n  text-transform: capitalize;\n}\n\n.expiration {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "SavedPaymentMethod__main___GN9xG",
	"paymentMethod": "SavedPaymentMethod__paymentMethod___ZrQAc",
	"brand": "SavedPaymentMethod__brand___LoKuB",
	"expiration": "SavedPaymentMethod__expiration___OwrwY"
};
export default ___CSS_LOADER_EXPORT___;
